import axios from 'axios';
import { setAuthToken } from './index';

const URL = `${process.env.REACT_APP_API_ENDPOINT}/serff-spi/filing-drafts`;

export const GET_FILING_DRAFTS = 'GET_FILING_DRAFTS';
export const GET_FILING_DRAFTS_CHUNK = 'GET_FILING_DRAFTS_CHUNK';
export const GET_FILING_DRAFTS_SUCCESS = 'GET_FILING_DRAFTS_SUCCESS';
export const REPLACE_FILING_DRAFTS_SUCCESS = 'REPLACE_FILING_DRAFTS_SUCCESS';
export const GET_FILING_DRAFTS_ERROR = 'GET_FILING_DRAFTS_ERROR';

export const GET_FILING_DRAFT = 'GET_FILING_DRAFT';
export const GET_FILING_DRAFT_SUCCESS = 'GET_FILING_DRAFT_SUCCESS';
export const GET_FILING_DRAFT_ERROR = 'GET_FILING_DRAFT_ERROR';

export const GET_FILING_DRAFT_SNAPSHOT = 'GET_FILING_DRAFT_SNAPSHOT';
export const GET_FILING_DRAFT_SNAPSHOT_SUCCESS = 'GET_FILING_DRAFT_SNAPSHOT_SUCCESS';
export const GET_FILING_DRAFT_SNAPSHOT_ERROR = 'GET_FILING_DRAFT_SNAPSHOT_ERROR';
export const GET_BATCH_ITEM = 'GET_BATCH_ITEM';

export const SELECT_BATCH = 'SELECT_BATCH';
export const SELECT_FILING_DRAFT = 'SELECT_FILING_DRAFT';
export const EDIT_FILING_DRAFT_SUCCESS = 'EDIT_FILING_DRAFT_SUCCESS';
export const INVALIDATE_FILING_DRAFT = 'INVALIDATE_FILING_DRAFT';

export const REMOVE_BATCH_ITEM = 'REMOVE_BATCH_ITEM';

export const ADD_FORM_SCHEDULE = 'ADD_FORM_SCHEDULE';
export const EDIT_FORM_SCHEDULE = 'EDIT_FORM_SCHEDULE';
export const REMOVE_FORM_SCHEDULE = 'REMOVE_FORM_SCHEDULE';
export const ATTACH_FORM_SCHEDULE_ATTACHMENT = 'ATTACH_FORM_SCHEDULE_ATTACHMENT';
export const REMOVE_FORM_SCHEDULE_ATTACHMENT = 'REMOVE_FORM_SCHEDULE_ATTACHMENT';

export const ADD_RATE_SCHEDULE = 'ADD_RATE_SCHEDULE';
export const EDIT_RATE_SCHEDULE = 'EDIT_RATE_SCHEDULE';
export const REMOVE_RATE_SCHEDULE = 'REMOVE_RATE_SCHEDULE';
export const ATTACH_RATE_SCHEDULE_ATTACHMENT = 'ATTACH_RATE_SCHEDULE_ATTACHMENT';
export const REMOVE_RATE_SCHEDULE_ATTACHMENT = 'REMOVE_RATE_SCHEDULE_ATTACHMENT';

export const APPLY_CHANGES = 'APPLY_CHANGES';
export const APPLY_CHANGES_SUCCESS = 'APPLY_CHANGES_SUCCESS';

export const EDIT_GENERAL_INFORMATION = 'EDIT_GENERAL_INFORMATION';
export const EDIT_RATE_DATA = 'EDIT_RATE_DATA';
export const EDIT_FILING_FEE = 'EDIT_FILING_FEE';
export const EDIT_STATE_SPECIFICS = 'EDIT_STATE_SPECIFICS';

export const BYPASS_DOCUMENT = 'BYPASS_DOCUMENT';
export const RESET_DOCUMENT = 'RESET_DOCUMENT';
export const EDIT_DOCUMENT = 'EDIT_DOCUMENT';
export const ADD_DOCUMENT_ATTACHMENT = 'ADD_DOCUMENT_ATTACHMENT';
export const REMOVE_DOCUMENT_ATTACHMENT = 'REMOVE_DOCUMENT_ATTACHMENT';

export const CREATE_DOCUMENT_TEMPLATE = 'CREATE_DOCUMENT_TEMPLATE';
export const EDIT_DOCUMENT_TEMPLATE = 'EDIT_DOCUMENT_TEMPLATE';
export const DELETE_DOCUMENT_TEMPLATE = 'DELETE_DOCUMENT_TEMPLATE';

export const EDIT_FILING_DRAFT_ITEM_SUCCESS = 'EDIT_FILING_DRAFT_ITEM_SUCCESS';
export const EDIT_SINGLE_GENERAL_INFORMATION = 'EDIT_SINGLE_GENERAL_INFORMATION';

export const ADD_SINGLE_FORM_SCHEDULE = 'ADD_SINGLE_FORM_SCHEDULE';
export const EDIT_SINGLE_FORM_SCHEDULE = 'EDIT_SINGLE_FORM_SCHEDULE';
export const REMOVE_SINGLE_FORM_SCHEDULE = 'REMOVE_SINGLE_FORM_SCHEDULE';
export const ATTACH_SINGLE_FORM_SCHEDULE_ATTACHMENT = 'ATTACH_SINGLE_FORM_SCHEDULE_ATTACHMENT';
export const ADD_FORM_OVERRIDES = 'ADD_FORM_OVERRIDES';
export const EDIT_FORM_OVERRIDES = 'EDIT_FORM_OVERRIDES';
export const ADD_RATE_OVERRIDES = 'ADD_RATE_OVERRIDES';
export const EDIT_RATE_OVERRIDES = 'EDIT_RATE_OVERRIDES';

export const ATTACH_SINGLE_FORM_SCHEDULE_OVERRIDE_ATTACHMENT = 'ATTACH_SINGLE_FORM_SCHEDULE_OVERRIDE_ATTACHMENT';
export const ATTACH_SINGLE_RATE_SCHEDULE_OVERRIDE_ATTACHMENT = 'ATTACH_SINGLE_RATE_SCHEDULE_OVERRIDE_ATTACHMENT';

export const REMOVE_SINGLE_FORM_SCHEDULE_OVERRIDE_ATTACHMENT = 'REMOVE_SINGLE_FORM_SCHEDULE_OVERRIDE_ATTACHMENT';
export const REMOVE_SINGLE_RATE_SCHEDULE_OVERRIDE_ATTACHMENT = 'REMOVE_SINGLE_RATE_SCHEDULE_OVERRIDE_ATTACHMENT';

export const ADD_SINGLE_RATE_SCHEDULE = 'ADD_SINGLE_RATE_SCHEDULE';
export const EDIT_SINGLE_RATE_SCHEDULE = 'EDIT_SINGLE_RATE_SCHEDULE';
export const REMOVE_SINGLE_RATE_SCHEDULE = 'REMOVE_SINGLE_RATE_SCHEDULE';
export const ATTACH_SINGLE_RATE_SCHEDULE_ATTACHMENT = 'ATTACH_SINGLE_RATE_SCHEDULE_ATTACHMENT';
export const REMOVE_SINGLE_RATE_SCHEDULE_ATTACHMENT = 'REMOVE_SINGLE_RATE_SCHEDULE_ATTACHMENT';
export const REMOVE_SINGLE_FORM_SCHEDULE_ATTACHMENT = 'REMOVE_SINGLE_FORM_SCHEDULE_ATTACHMENT';

export const ATTACH_SUPPORTING_TEMPLATE_ATTACHMENT = 'ATTACH_SUPPORTING_TEMPLATE_ATTACHMENT';
export const REMOVE_SUPPORTING_TEMPLATE_ATTACHMENT = 'REMOVE_SUPPORTING_TEMPLATE_ATTACHMENT';

export const EXCLUDE_SINGLE_FORM_TEMPLATE = 'EXCLUDE_SINGLE_FORM_TEMPLATE';
export const EXCLUDE_SINGLE_RATE_TEMPLATE = 'EXCLUDE_SINGLE_RATE_TEMPLATE';

export const INCLUDE_DEFAULT_FORM_ITEMS = 'INCLUDE_DEFAULT_FORM_ITEMS';
export const INCLUDE_DEFAULT_RATE_ITEMS = 'INCLUDE_DEFAULT_RATE_ITEMS';

export const ARCHIVE_FILING_DRAFT_SUCCESS = 'ARCHIVE_FILING_DRAFT_SUCCESS';

export const RESET_FORM_SCHEDULE_ITEM_OVERRIDE = 'RESET_FORM_SCHEDULE_ITEM_OVERRIDE';
export const RESET_RATE_SCHEDULE_ITEM_OVERRIDE = 'RESET_RATE_SCHEDULE_ITEM_OVERRIDE';

export const getFilingDrafts = ({
  limit,
  offset,
  firstLoad = true,
  archived,
  productIds = [],
}, replaceData) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    if (!replaceData) {
      if (firstLoad) {
        dispatch({ type: GET_FILING_DRAFTS });
      } else {
        dispatch({ type: GET_FILING_DRAFTS_CHUNK });
      }
    }
    try {
      const params = {
        limit,
        offset,
        archived,
        productIds,
      };
      const res = await axios.get(`${URL}`, { params });
      if (replaceData) {
        dispatch({
          type: REPLACE_FILING_DRAFTS_SUCCESS,
          payload: { list: res.data, offset },
        });
      } else {
        dispatch({
          type: GET_FILING_DRAFTS_SUCCESS,
          payload: { firstLoad, list: res.data },
        });
      }
    } catch ({ response }) {
      dispatch({
        type: GET_FILING_DRAFTS_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};

export const createFilingDraft = (form) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}`, form);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const getFilingDraftItem = ({
  filingDraftId,
  batchId,
  itemId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.get(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}`);
      dispatch({
        type: GET_BATCH_ITEM,
        payload: { batchId, itemId, data: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editFilingDraftItem = ({
  filingDraftId,
  batchId,
  itemId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}`, data);
      // dispatch({
      //   type: EDIT_FILING_DRAFT_ITEM_SUCCESS,
      //   payload: { batchId, itemId, data },
      // });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const getFilingDraft = (filingDraftId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_FILING_DRAFT });
    try {
      const res = await axios.get(`${URL}/${filingDraftId}`);
      dispatch({
        type: GET_FILING_DRAFT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_FILING_DRAFT_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};

export const addRateData = ({
  filingDraftId,
  batchId,
  itemId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: APPLY_CHANGES });
      const res = await axios
        .patch(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/rate-data`, data);
      dispatch({
        type: EDIT_RATE_DATA,
        payload: {
          batchId,
          itemId,
          rateData: res.data,
        },
      });
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      return res.data;
    } catch ({ response }) {
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      throw response && response.data;
    }
  };
};

export const attachRateScheduleAttachment = ({
  filingDraftId,
  batchId,
  rateId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${filingDraftId}/batches/${batchId}/template/rates/${rateId}/attachments`, data);
      dispatch({
        type: ATTACH_RATE_SCHEDULE_ATTACHMENT,
        payload: {
          rateId,
          batchId,
          attachment: res.data,
        },
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const attachFormScheduleAttachment = ({
  filingDraftId,
  batchId,
  formId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${filingDraftId}/batches/${batchId}/template/forms/${formId}/attachments`, data);
      dispatch({
        type: ATTACH_FORM_SCHEDULE_ATTACHMENT,
        payload: {
          formId,
          batchId,
          attachment: res.data,
        },
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addFormSchedule = ({ filingDraftId, batchId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .post(`${URL}/${filingDraftId}/batches/${batchId}/template/forms`, data);
      dispatch({
        type: ADD_FORM_SCHEDULE,
        payload: {
          batchId,
          form: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editFormSchedule = ({
  filingDraftId,
  batchId,
  formId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .patch(`${URL}/${filingDraftId}/batches/${batchId}/template/forms/${formId}`, data);
      dispatch({
        type: EDIT_FORM_SCHEDULE,
        payload: {
          batchId,
          form: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const removeFormSchedule = ({ filingDraftId, batchId, formId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/template/forms/${formId}`);
      dispatch({
        type: REMOVE_FORM_SCHEDULE,
        payload: {
          batchId,
          formId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const removeTemplateFormScheduleAttachment = ({
  filingDraftId,
  batchId,
  formId,
  attachmentId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/template/forms/${formId}/attachments/${attachmentId}`);
      dispatch({
        type: REMOVE_FORM_SCHEDULE_ATTACHMENT,
        payload: {
          batchId,
          formId,
          attachmentId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const removeTemplateRateScheduleAttachment = ({
  filingDraftId,
  batchId,
  rateId,
  attachmentId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/template/rates/${rateId}/attachments/${attachmentId}`);
      dispatch({
        type: REMOVE_RATE_SCHEDULE_ATTACHMENT,
        payload: {
          batchId,
          rateId,
          attachmentId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const includeDefaultFormItems = ({
  filingDraftId,
  batchId,
  itemId,
  includeTemplate,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .patch(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/form`, { includeTemplate });
      dispatch({
        type: INCLUDE_DEFAULT_FORM_ITEMS,
        payload: {
          batchId,
          itemId,
          includeTemplate,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const includeDefaultRateItems = ({
  filingDraftId,
  batchId,
  itemId,
  includeTemplate,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .patch(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/rate`, { includeTemplate });
      dispatch({
        type: INCLUDE_DEFAULT_RATE_ITEMS,
        payload: {
          batchId,
          itemId,
          includeTemplate,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addSingleFormSchedule = ({
  filingDraftId,
  batchId,
  itemId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .post(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/form/entries`, data);
      dispatch({
        type: ADD_SINGLE_FORM_SCHEDULE,
        payload: {
          batchId,
          itemId,
          form: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editSingleFormSchedule = ({
  filingDraftId,
  batchId,
  itemId,
  formId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .patch(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/form/entries/${formId}`, data);
      dispatch({
        type: EDIT_SINGLE_FORM_SCHEDULE,
        payload: {
          batchId,
          itemId,
          form: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editSingleRateSchedule = ({
  filingDraftId,
  batchId,
  itemId,
  rateId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .patch(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/rate/entries/${rateId}`, data);
      dispatch({
        type: EDIT_SINGLE_RATE_SCHEDULE,
        payload: {
          batchId,
          itemId,
          rate: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const removeSingleFormSchedule = ({
  filingDraftId,
  batchId,
  itemId,
  formId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/form/entries/${formId}`);
      dispatch({
        type: REMOVE_SINGLE_FORM_SCHEDULE,
        payload: {
          batchId,
          itemId,
          formId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const removeSingleRateSchedule = ({
  filingDraftId,
  batchId,
  itemId,
  rateId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/rate/entries/${rateId}`);
      dispatch({
        type: REMOVE_SINGLE_RATE_SCHEDULE,
        payload: {
          batchId,
          itemId,
          rateId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const removeSingleRateScheduleAttachment = ({
  filingDraftId,
  batchId,
  itemId,
  rateId,
  attachmentId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/rate/entries/${rateId}/attachments/${attachmentId}`);
      dispatch({
        type: REMOVE_SINGLE_RATE_SCHEDULE_ATTACHMENT,
        payload: {
          batchId,
          itemId,
          rateId,
          attachmentId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const removeSingleFormScheduleAttachment = ({
  filingDraftId,
  batchId,
  itemId,
  formId,
  attachmentId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/form/entries/${formId}/attachments/${attachmentId}`);
      dispatch({
        type: REMOVE_SINGLE_FORM_SCHEDULE_ATTACHMENT,
        payload: {
          batchId,
          itemId,
          formId,
          attachmentId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const attachSingleFormScheduleAttachment = ({
  filingDraftId,
  batchId,
  formId,
  itemId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .post(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/form/entries/${formId}/attachments`, data);
      dispatch({
        type: ATTACH_SINGLE_FORM_SCHEDULE_ATTACHMENT,
        payload: {
          formId,
          batchId,
          itemId,
          attachment: res.data,
        },
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const removeRateSchedule = ({ filingDraftId, batchId, rateId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/template/rates/${rateId}`);
      dispatch({
        type: REMOVE_RATE_SCHEDULE,
        payload: {
          batchId,
          rateId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editRateSchedule = ({
  filingDraftId,
  batchId,
  rateId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .patch(`${URL}/${filingDraftId}/batches/${batchId}/template/rates/${rateId}`, data);
      dispatch({
        type: EDIT_RATE_SCHEDULE,
        payload: {
          batchId,
          rate: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addRateSchedule = ({ filingDraftId, batchId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .post(`${URL}/${filingDraftId}/batches/${batchId}/template/rates`, data);
      dispatch({
        type: ADD_RATE_SCHEDULE,
        payload: {
          batchId,
          rate: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addSingleRateSchedule = ({
  filingDraftId,
  batchId,
  itemId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .post(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/rate/entries`, data);
      dispatch({
        type: ADD_SINGLE_RATE_SCHEDULE,
        payload: {
          batchId,
          itemId,
          rate: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const attachSingleRateScheduleAttachment = ({
  filingDraftId,
  batchId,
  itemId,
  rateId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .post(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/rate/entries/${rateId}/attachments`, data);
      dispatch({
        type: ATTACH_SINGLE_RATE_SCHEDULE_ATTACHMENT,
        payload: {
          rateId,
          batchId,
          itemId,
          attachment: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editSingleGeneralInformation = ({
  filingDraftId,
  batchId,
  itemId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: APPLY_CHANGES });
      const res = await axios
        .patch(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/general-information`, data);
      dispatch({
        type: EDIT_SINGLE_GENERAL_INFORMATION,
        payload: {
          batchId,
          itemId,
          generalInformation: res.data,
        },
      });
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      return res.data;
    } catch ({ response }) {
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      throw response && response.data;
    }
  };
};

export const editGeneralInformation = ({ filingDraftId, batchId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: APPLY_CHANGES });
      const res = await axios
        .patch(`${URL}/${filingDraftId}/batches/${batchId}/template/general-information`, data);
      dispatch({
        type: EDIT_GENERAL_INFORMATION,
        payload: {
          batchId,
          generalInformation: res.data,
        },
      });
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      return res.data;
    } catch ({ response }) {
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      throw response && response.data;
    }
  };
};

export const editStateSpecifics = ({
  filingDraftId,
  batchId,
  itemId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: APPLY_CHANGES });
      const res = await axios
        .patch(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/state-specifics`, data);
      dispatch({
        type: EDIT_STATE_SPECIFICS,
        payload: {
          batchId,
          itemId,
          stateSpecifics: res.data,
        },
      });
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      return res.data;
    } catch ({ response }) {
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      throw response && response.data;
    }
  };
};

export const editFilingFee = ({
  filingDraftId,
  batchId,
  itemId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: APPLY_CHANGES });
      const res = await axios
        .patch(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/fee`, data);
      dispatch({
        type: EDIT_FILING_FEE,
        payload: {
          batchId,
          itemId,
          fee: res.data,
        },
      });
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      return res.data;
    } catch ({ response }) {
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      throw response && response.data;
    }
  };
};

export const submitFiling = ({
  filingDraftId,
  batchId,
  itemId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      await axios.post(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/submission`);
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const resetSupportingDocument = ({
  filingDraftId,
  batchId,
  itemId,
  supportingId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: APPLY_CHANGES });
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/supportings/${supportingId}`);
      dispatch({
        type: RESET_DOCUMENT,
        payload: {
          batchId,
          itemId,
          supportingId,
        },
      });
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      return res.data;
    } catch ({ response }) {
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      throw response && response.data;
    }
  };
};

export const addSupportingAttachment = ({
  filingDraftId,
  batchId,
  itemId,
  supportingId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: APPLY_CHANGES });
      const res = await axios
        .post(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/supportings/${supportingId}/attachments`, data);
      dispatch({
        type: ADD_DOCUMENT_ATTACHMENT,
        payload: {
          batchId,
          itemId,
          supportingId,
          data: res.data,
        },
      });
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      return res.data;
    } catch ({ response }) {
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      throw response && response.data;
    }
  };
};

export const removeSupportingAttachment = ({
  filingDraftId,
  batchId,
  itemId,
  supportingId,
  attachmentId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: APPLY_CHANGES });
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/supportings/${supportingId}/attachments/${attachmentId}`);
      dispatch({
        type: REMOVE_DOCUMENT_ATTACHMENT,
        payload: {
          batchId,
          itemId,
          supportingId,
          attachmentId,
        },
      });
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      return res.data;
    } catch ({ response }) {
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      throw response && response.data;
    }
  };
};

export const editSupportingDocument = ({
  filingDraftId,
  batchId,
  itemId,
  supportingId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: APPLY_CHANGES });
      const res = await axios
        .patch(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/supportings/${supportingId}`, data);
      dispatch({
        type: EDIT_DOCUMENT,
        payload: {
          batchId,
          itemId,
          supportingId,
          data: res.data,
        },
      });
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      return res.data;
    } catch ({ response }) {
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      throw response && response.data;
    }
  };
};

export const createSupportingDocument = ({
  filingDraftId,
  batchId,
  itemId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: APPLY_CHANGES });
      const res = await axios
        .post(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/supportings`, data);
      dispatch({
        type: BYPASS_DOCUMENT,
        payload: {
          batchId,
          itemId,
          data: res.data,
        },
      });
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      return res.data;
    } catch ({ response }) {
      dispatch({ type: APPLY_CHANGES_SUCCESS });
      throw response && response.data;
    }
  };
};

export const createDocumentTemplate = ({
  filingDraftId,
  batchId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .post(`${URL}/${filingDraftId}/batches/${batchId}/blueprint/supportings`, data);
      dispatch({
        type: CREATE_DOCUMENT_TEMPLATE,
        payload: { batchId, data: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addSupportingTemplateAttachment = ({
  filingDraftId,
  batchId,
  supportingId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .post(`${URL}/${filingDraftId}/batches/${batchId}/blueprint/supportings/${supportingId}/attachments`, data);
      dispatch({
        type: ATTACH_SUPPORTING_TEMPLATE_ATTACHMENT,
        payload: {
          batchId,
          supportingId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const removeSupportingTemplateAttachment = ({
  filingDraftId,
  batchId,
  supportingId,
  attachmentId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/blueprint/supportings/${supportingId}/attachments/${attachmentId}`);
      dispatch({
        type: REMOVE_SUPPORTING_TEMPLATE_ATTACHMENT,
        payload: {
          batchId,
          supportingId,
          attachmentId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editDocumentationTemplate = ({
  filingDraftId,
  batchId,
  supportingId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .patch(`${URL}/${filingDraftId}/batches/${batchId}/blueprint/supportings/${supportingId}`, data);
      dispatch({
        type: EDIT_DOCUMENT_TEMPLATE,
        payload: { batchId, data: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteDocumentTemplate = ({
  filingDraftId,
  batchId,
  templateId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/blueprint/supportings/${templateId}`);
      dispatch({
        type: DELETE_DOCUMENT_TEMPLATE,
        payload: { batchId, templateId },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const excludeTemplateForm = ({
  filingDraftId,
  batchId,
  itemId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .put(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/form/excludes`, data);
      dispatch({
        type: EXCLUDE_SINGLE_FORM_TEMPLATE,
        payload: {
          batchId,
          itemId,
          excludes: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const excludeTemplateRate = ({
  filingDraftId,
  batchId,
  itemId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .put(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/rate/excludes`, data);
      dispatch({
        type: EXCLUDE_SINGLE_RATE_TEMPLATE,
        payload: {
          batchId,
          itemId,
          excludes: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addBatchItem = ({
  filingDraftId,
  batchId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios
        .post(`${URL}/${filingDraftId}/batches/${batchId}/items`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const removeBatchItem = ({
  filingDraftId,
  batchId,
  itemId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}`);
      dispatch({
        type: REMOVE_BATCH_ITEM,
        payload: {
          batchId,
          itemId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const createFormScheduleOverrides = ({
  filingDraftId,
  batchId,
  itemId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .post(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/form/overrides`, data);
      dispatch({
        type: ADD_FORM_OVERRIDES,
        payload: {
          batchId,
          itemId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editFormScheduleOverrides = ({
  filingDraftId,
  batchId,
  itemId,
  overrideId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .patch(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/form/overrides/${overrideId}`, data);
      dispatch({
        type: EDIT_FORM_OVERRIDES,
        payload: {
          batchId,
          itemId,
          overrideId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const createRateScheduleOverrides = ({
  filingDraftId,
  batchId,
  itemId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .post(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/rate/overrides`, data);
      dispatch({
        type: ADD_RATE_OVERRIDES,
        payload: {
          batchId,
          itemId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editRateScheduleOverrides = ({
  filingDraftId,
  batchId,
  itemId,
  overrideId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .patch(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/rate/overrides/${overrideId}`, data);
      dispatch({
        type: EDIT_RATE_OVERRIDES,
        payload: {
          batchId,
          itemId,
          overrideId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addFilingDraftItemFormAttachment = ({
  filingDraftId,
  batchId,
  itemId,
  overrideId,
  attachment,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .post(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/form/overrides/${overrideId}/attachments`, attachment);
      dispatch({
        type: ATTACH_SINGLE_FORM_SCHEDULE_OVERRIDE_ATTACHMENT,
        payload: {
          batchId,
          itemId,
          overrideId,
          attachment: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addFilingDraftItemRateAttachment = ({
  filingDraftId,
  batchId,
  itemId,
  overrideId,
  attachment,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .post(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/rate/overrides/${overrideId}/attachments`, attachment);
      dispatch({
        type: ATTACH_SINGLE_RATE_SCHEDULE_OVERRIDE_ATTACHMENT,
        payload: {
          batchId,
          itemId,
          overrideId,
          attachment: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteFilingDraftItemFormAttachment = ({
  filingDraftId,
  batchId,
  itemId,
  overrideId,
  attachmentId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/form/overrides/${overrideId}/attachments/${attachmentId}`);
      dispatch({
        type: REMOVE_SINGLE_FORM_SCHEDULE_OVERRIDE_ATTACHMENT,
        payload: {
          batchId,
          itemId,
          overrideId,
          attachmentId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteFilingDraftItemRateAttachment = ({
  filingDraftId,
  batchId,
  itemId,
  overrideId,
  attachmentId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/rate/overrides/${overrideId}/attachments/${attachmentId}`);
      dispatch({
        type: REMOVE_SINGLE_RATE_SCHEDULE_OVERRIDE_ATTACHMENT,
        payload: {
          batchId,
          itemId,
          overrideId,
          attachmentId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const resetFilingDraftItemFormOverride = ({
  filingDraftId,
  batchId,
  itemId,
  overrideId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/form/overrides/${overrideId}`);
      dispatch({
        type: RESET_FORM_SCHEDULE_ITEM_OVERRIDE,
        payload: {
          batchId,
          itemId,
          overrideId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const resetFilingDraftItemRateOverride = ({
  filingDraftId,
  batchId,
  itemId,
  overrideId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios
        .delete(`${URL}/${filingDraftId}/batches/${batchId}/items/${itemId}/rate/overrides/${overrideId}`);
      dispatch({
        type: RESET_RATE_SCHEDULE_ITEM_OVERRIDE,
        payload: {
          batchId,
          itemId,
          overrideId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const archiveFilingDraft = (filingDraftId, archived) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${filingDraftId}`, { archived });
      dispatch({
        type: ARCHIVE_FILING_DRAFT_SUCCESS,
        payload: { id: filingDraftId },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editFilingDraft = (filingDraftId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${filingDraftId}`, data);
      // dispatch({
      //   type: EDIT_FILING_DRAFT_SUCCESS,
      //   payload: data,
      // });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const getFilingDraftSnapshot = (data, batchId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: GET_FILING_DRAFT_SNAPSHOT,
      });
      const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/serff-spi/filing-draft-item-snapshots/search`, data);
      dispatch({
        type: GET_FILING_DRAFT_SNAPSHOT_SUCCESS,
        payload: {
          batchId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_FILING_DRAFT_SNAPSHOT_ERROR,
      });
      throw response && response.data;
    }
  };
};

export const getFilingDraftFormScanResults = (params) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/ai/scan-results`, {
        params,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
}

export const scanFilingDraftItemAttachment = ({ filingDraftId, itemId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/ai/filing-drafts/${filingDraftId}/items/${itemId}/attachments/scan`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
}

export const selectBatch = (payload) => ({
  type: SELECT_BATCH,
  payload,
});

export const selectFiling = (payload) => ({
  type: SELECT_FILING_DRAFT,
  payload,
});

export const invalidateFilingDraft = () => ({
  type: INVALIDATE_FILING_DRAFT,
});
