import {
  GET_CHECKLIST_ITEMS,
  GET_CHECKLIST_ITEMS_ERROR,
  GET_CHECKLIST_ITEMS_SUCCESS,
  CREATE_CHECKLIST_ITEM,
  EDIT_CHECKLIST_ITEM,
} from '../actions/aiChecklistItems';

const initialState = {
  isFetching: false,
  isFetched: false,
  data: [],
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHECKLIST_ITEMS: {
      return {
        isFetching: true,
        isFetched: false,
        data: [],
        error: null,
      }
    }
    case GET_CHECKLIST_ITEMS_SUCCESS: {
      return {
        isFetching: false,
        isFetched: true,
        data: action.payload,
        error: null,
      }
    }
    case GET_CHECKLIST_ITEMS_ERROR: {
      return {
        isFetching: false,
        isFetched: false,
        data: [],
        error: action.payload,
      }
    }
    case CREATE_CHECKLIST_ITEM: {
      return {
        isFetching: false,
        isFetched: true,
        data: [...state.data, action.payload],
        error: null,
      }
    }
    case EDIT_CHECKLIST_ITEM: {
      const { id, data } = action.payload;

      return {
        isFetching: false,
        isFetched: true,
        error: null,
        data: state.data.map(item => {
          if (item._id === id) {
            return data;
          }
          return item;
        })
      }
    }
    default:
      return state;
  }
}
